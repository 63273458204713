<template>

    <!--  LOGIN FORM  -->
    <div v-if="type === CustomerAuthFormType.LOGIN" class="sim-auth-form__wrapper">
        <div class="sim-auth-form__form">

            <slot name="heading" />

            <CustomerAuthLoginForm
                ref="loginForm"
                class="w-full"
                :color="color"
                :on-submit="handleLoginFormSubmit"
            />

            <!--                <UiDivider :label="$t('accessibility.socials.or')" />-->

            <!--                <CustomerAuthThirdParties :button-class="dark ? 'sim-ghost-btn-hover&#45;&#45;alt' : undefined" />-->

            <hr class="my-0 md:hidden">
        </div>

        <!--  do you already have an account?  -->
        <div class="md:bg-primary-900 flex flex-col gap-2 p-8 md:p-10">
            <div v-if="$slots.heading" class="hidden flex-col items-end md:flex">
                <UiButtonClose variant="dark" @click="close" />
            </div>

            <div class="sim-auth-form__additional-info md:px-5">
                <div class="flex w-full flex-col items-center gap-3.5 md:gap-6">
                    <IconUserPlus class="hidden md:block" />

                    <span class="sim-subtitle sim-font-regular text-center md:max-w-[8.75rem]">
                        {{ $t('labels.dont_have_an_account') }}
                    </span>

                    <UiButton
                        class="w-full"
                        @click="type = CustomerAuthFormType.REGISTRATION"
                    >
                        {{ $t('labels.registration') }}
                    </UiButton>
                </div>

                <hr class="my-0 md:hidden">

                <div class="text-simplo text-center">
                    <h3 class="sim-text-small sim-font-bold text-primary-900 mb-2.5 uppercase md:text-white">
                        {{ $t('auth.forgotten_password_question') }}
                    </h3>
                    <UiButton
                        variant="bare"
                        size="none"
                        class="hover:underline"
                        @click="type = CustomerAuthFormType.PASSWORD_RESET"
                    >
                        <template #leading>
                            <IconMail />
                        </template>
                        {{ $t('auth.forgotten_password') }}
                    </UiButton>
                </div>
            </div>
        </div>
    </div> <!--  end of login form  -->

    <!-- ------------------------------------------------------------------------------------------------------- -->

    <!--  REGISTRATION FORM  -->
    <div v-else-if="type === CustomerAuthFormType.REGISTRATION" class="sim-auth-form__wrapper">

        <div class="sim-auth-form__form">

            <slot name="heading" />

            <CustomerAuthRegisterForm
                ref="registerForm"
                class="w-full"
                :color="color"
                :on-submit="handleRegisterFormSubmit"
            />

            <!--                <UiDivider :label="$t('accessibility.socials.or')" />-->

            <!--                <CustomerAuthThirdParties :button-class="dark ? 'sim-ghost-btn-hover&#45;&#45;alt' : undefined" />-->

            <i18n-t keypath="auth.gdpr_agreement" tag="p" class="sim-auth-form__gdpr">
                <template #register>
                    <span class="sim-font-bold">
                        {{ $t('labels.register') }}
                    </span>
                </template>

                <template #continue_via>
                    <span class="sim-font-bold">
                        {{ $t('auth.continue_via') }}
                    </span>
                </template>

                <template #gdpr>
                    <NuxtLink class="sim-link">
                        {{ $t('auth.gdpr') }}
                    </NuxtLink>
                </template>

                <template #terms_of_use>
                    <NuxtLink class="sim-link">
                        {{ $t('auth.terms_of_use') }}
                    </NuxtLink>
                </template>
            </i18n-t>

            <hr class="my-0 md:hidden">
        </div>

        <!--  benefits  -->
        <div class="md:bg-primary-900 flex flex-col gap-2 p-8 md:p-10">
            <div v-if="$slots.heading" class="hidden flex-col items-end md:flex">
                <UiButtonClose variant="dark" @click="close" />
            </div>

            <div class="sim-auth-form__additional-info">
                <div class="hidden w-full flex-col items-center gap-6 md:flex">
                    <IconRocket />

                    <span class="sim-subtitle sim-font-regular mb-4 text-center md:max-w-48">
                        {{ $t('auth.registration_benefits') }}
                    </span>

                    <ul>
                        <li v-for="index in 5" :key="index" class="sim-auth-form__benefit">
                            <IconCheck class="text-simplo" aria-hidden="true" />
                            {{ $t(`auth.benefit_${index}`) }}
                        </li>
                    </ul>

                </div>

                <div class="text-simplo text-center">
                    <h3 class="sim-text-small sim-font-bold text-primary-900 mb-2.5 uppercase md:text-white">
                        {{ $t('auth.already_have_account') }}
                    </h3>
                    <UiButton
                        variant="bare"
                        size="none"
                        class="hover:underline"
                        @click="type = CustomerAuthFormType.LOGIN"
                    >
                        <template #leading>
                            <IconPerson />
                        </template>
                        {{ $t('labels.log_in') }}
                    </UiButton>
                </div>
            </div>

        </div>

    </div> <!--  end of registration form  -->

    <!-- ------------------------------------------------------------------------------------------------------- -->

    <!--  PASSWORD RESET FORM  -->
    <div v-else-if="type === CustomerAuthFormType.PASSWORD_RESET">
        <div class="sim-auth-form__form sim-auth-form__form--pass-reset">

            <slot name="heading" />

            <p class="sim-text-normal">
                {{ $t('auth.forgotten_password_text') }}
            </p>

            <div class="flex w-full flex-col gap-3">
                <CustomerAuthSendPasswordResetTokenForm
                    ref="sendPasswordResetTokenForm"
                    :color="color"
                    class="w-full"
                    :on-submit="handleSendPasswordResetTokenFormSubmit"
                />

                <UiButton
                    variant="plain"
                    color="primary"
                    class="w-full"
                    @click="type = CustomerAuthFormType.LOGIN"
                >
                    {{ $t('auth.back_to_login') }}
                </UiButton>
            </div>
        </div>
    </div> <!--  end of password reset form  -->
</template>

<script lang="ts" setup>
import type { schema as LoginFormSchema } from './CustomerAuthLoginForm.vue'
import type { schema as RegisterFormSchema } from './CustomerAuthRegisterForm.vue'
import type {
    schema as SendPasswordResetTokenFormSchema
} from './CustomerAuthSendPasswordResetTokenForm.vue'
import { CustomerAuthFormType } from '../../../../types/components'
import type {
    CustomerAuthLoginForm,
    CustomerAuthSendPasswordResetTokenForm,
    CustomerAuthRegisterForm
} from '#components'
import { EcoMailTag } from '../../../assets/ts/enums/eco-mail'
import type { BaseUiInputColors } from '../../base/BaseUiInput.vue'

const {
    close,
    dark,
    color,
} = defineProps<{
    close?: () => void
    dark?: boolean
    color?: BaseUiInputColors
}>();

const type = defineModel<CustomerAuthFormType>({
    default: CustomerAuthFormType.LOGIN,
})

const emit = defineEmits<{
    submitted: []
}>()

const ecoMail = useEcoMail()

// LOGIN FORM SUBMIT ...................................................................................................
const loginForm = ref<ComponentInstance<typeof CustomerAuthLoginForm> | null>(null)

async function handleLoginFormSubmit(payload: LoginFormSchema) {
    await useAuth().logIn({
        email: payload.email,
        password: payload.password,
    })

    emit('submitted')
}

// REGISTRATION FORM SUBMIT ............................................................................................
const registerForm = ref<ComponentInstance<typeof CustomerAuthRegisterForm> | null>(null)

async function handleRegisterFormSubmit(payload: RegisterFormSchema) {
    await useAuth().register({
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
        password: payload.password,
        passwordConfirmation: payload.password_confirmation,
        shouldSubscribeToNewsletter: payload.is_subscribed,
    })

    // purposely not awaiting
    ecoMail.subscribe(EcoMailTag.REGISTRATION, payload.email, {
        first_name: payload.first_name,
        last_name: payload.last_name,
    }).then(() => {
        if (payload.is_subscribed) ecoMail.subscribe(EcoMailTag.NEWSLETTER, payload.email)
    })

    emit('submitted')
}

// PASSWORD RESET FORM SUBMIT ........................................................................................

const sendPasswordResetTokenForm = ref<ComponentInstance<typeof CustomerAuthSendPasswordResetTokenForm> | null>(null)

async function handleSendPasswordResetTokenFormSubmit(payload: SendPasswordResetTokenFormSchema) {
    await useAuth().sendPasswordResetToken({
        email: payload.email,
    })

    emit('submitted')
}

</script>

<style lang="scss" scoped>
.sim-auth-form__wrapper {
    @include more-than(md) {
        display: grid;
        grid-template-columns: 55% 45%;
    }
}

.sim-auth-form__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 2.5rem;
    flex-grow: 1;

    padding: 2rem 2rem 0;

    @include more-than(md) {
        gap: 3rem;
        padding: 2.5rem;
    }
}

.sim-auth-form__form--pass-reset {
    gap: 1.25rem;

    @include more-than(md) {
        gap: 1.25rem;
    }

    padding-bottom: 2rem;
}

.sim-auth-form__additional-info {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    gap: 2rem;

    @include more-than(md) {
        color: $sim-c-white;
        gap: 0.75rem;
    }
}

.sim-auth-form__gdpr {
    text-align: center;

    @include sim-text-tiny;

    @include more-than(md) {
        text-align: left;
    }
}

.sim-auth-form__benefit {
    display: flex;
    gap: 1.375rem;

    @include sim-text-normal;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

</style>
