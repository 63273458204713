<template>
    <CoreUiForm
        ref="form"
        v-slot="{ formData, globalFormError, isFormSubmitting }"
        :schema="schema"
        :on-submit="onSubmit"
    >
        <CoreUiFormRow>
            <BaseUiFormInput
                v-model:form="formData.first_name"
                :color="color"
                autocomplete="given-name"
                :label="$t('labels.name')"
                :placeholder="$t('labels.your_name')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <BaseUiFormInput
                v-model:form="formData.last_name"
                :color="color"
                autocomplete="family-name"
                :label="$t('labels.surname')"
                :placeholder="$t('labels.your_surname')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <BaseUiFormInput
                v-model:form="formData.email"
                :color="color"
                autocomplete="email"
                :label="$t('labels.email')"
                :placeholder="$t('labels.your_email_address')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <BaseUiFormInput
                v-model:form="formData.password"
                :color="color"
                type="password"
                autocomplete="new-password"
                :label="$t('labels.password')"
                :placeholder="$t('labels.your_password')"
                :description-id="passwordRecommendationId"
                show-password-toggle
                @input="calculatePasswordStrength"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <BaseUiFormInput
                v-model:form="formData.password_confirmation"
                :color="color"
                type="password"
                autocomplete="new-password"
                :label="$t('labels.password_again')"
                :placeholder="$t('labels.your_password_again')"
                :description-id="passwordRecommendationId"
                show-password-toggle
            />
        </CoreUiFormRow>

        <div class="mt-2 flex flex-col">
            <div class="flex w-full items-center justify-between gap-5">
                <BaseUiProgressBar :progress="passwordStrengthValue" />
                <UiButton
                    variant="bare"
                    size="none"
                    @click="showContent = !showContent"
                >
                    <span class="sim-text-extra-small text-nowrap">
                        {{ passwordStrengthText }}
                    </span>
                    <template #trailing>
                        <IconChevron :up="showContent" size="xs" class="text-simplo" />
                    </template>
                </UiButton>
            </div>
            <BaseUtilCollapse v-model="showContent">
                <span :id="passwordRecommendationId" class="sim-text-small block pt-5">
                    {{ $t('labels.password_recommendation') }}
                </span>
            </BaseUtilCollapse>
        </div>

        <CoreUiFormRow indented>
            <BaseUiFormCheckbox
                v-model:form="formData.is_subscribed"
                :label="$t('labels.sign_up_for_newsletter')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow indented no-margin-bottom>
            <UiButton
                class="grow"
                color="primary"
                size="giant"
                type="submit"
                :aria-describedby="globalFormError.id"
                :loading="isFormSubmitting"
            >
                {{ $t('labels.register') }}
            </UiButton>
        </CoreUiFormRow>

        <BaseUiFormError v-if="globalFormError.message" class="pt-4" :error-id="globalFormError.id">
            {{ globalFormError.message }}
        </BaseUiFormError>
    </CoreUiForm>
</template>

<script lang="ts" setup>
import type { ComponentInstance } from '@core-types/utility'
import { CoreUiForm } from '#components'
import { z } from 'zod'
import type { BaseUiInputColors } from '../../base/BaseUiInput.vue'

const { t } = useI18n()

const passwordRecommendationId = useId()

const {
    onSubmit,
    color,
} = defineProps<{
    onSubmit: (data: z.infer<typeof schema>) => void | Promise<void>
    color?: BaseUiInputColors
}>()

const passwordStrengthValue = ref<number>(0)
const passwordStrengthText = ref<string>(t('labels.password_strength.very_weak'))

const showContent = ref<boolean>(false)

const form = ref<ComponentInstance<typeof CoreUiForm> | null>(null)

const schema = z.object({
    first_name: zStringMediumLengthType,
    last_name: zStringMediumLengthType,
    email: zStringMailType,
    password: zStringPassword,
    password_confirmation: zStringPassword,
    is_subscribed: z.boolean().optional().default(false),
})

export type schema = z.infer<typeof schema>

defineExpose({
    formComponent: form,
})

const getPasswordStrengthValue = (password: string): number => {
    let strength = 0

    if (password.length > 8) strength += 1
    if (/[A-Z]/.test(password)) strength += 1
    if (/[a-z]/.test(password)) strength += 1
    if (/[0-9]/.test(password)) strength += 1
    if (/[^A-Za-z0-9]/.test(password)) strength += 1

    return strength
}

const getPasswordStrengthText = (strength: number): string => {
    switch (strength) {
        case 1: return t('labels.password_strength.very_weak')
        case 2: return t('labels.password_strength.weak')
        case 3: return t('labels.password_strength.moderate')
        case 4: return t('labels.password_strength.strong')
        case 5: return t('labels.password_strength.very_strong')
        default: return t('labels.password_strength.very_weak')
    }
}

function calculatePasswordStrength(event: InputEvent) {
    const password = (event.target as HTMLInputElement).value

    passwordStrengthValue.value = (getPasswordStrengthValue(password) / 5) * 100
    passwordStrengthText.value = getPasswordStrengthText(getPasswordStrengthValue(password))
}

</script>
