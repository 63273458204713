<template>
    <CoreUiForm
        ref="form"
        v-slot="{ formData, globalFormError, isFormSubmitting }"
        :schema="schema"
        :on-submit="onSubmit"
    >
        <CoreUiFormRow>
            <!--  EMAIL INPUT  -->
            <BaseUiFormInput
                v-model:form="formData.email"
                type="email"
                :color="color"
                autocomplete="username"
                :label="$t('labels.email')"
                :placeholder="$t('labels.your_email_address')"
            />
        </CoreUiFormRow>


        <CoreUiFormRow indented no-margin-bottom>
            <UiButton
                class="grow"
                color="primary"
                size="giant"
                type="submit"
                :aria-describedby="globalFormError.id"
                :loading="isFormSubmitting"
            >
                {{ $t('labels.submit') }}
            </UiButton>
        </CoreUiFormRow>

        <BaseUiFormError v-if="globalFormError.message" class="pt-4" :error-id="globalFormError.id">
            {{ globalFormError.message }}
        </BaseUiFormError>
    </CoreUiForm>
</template>

<script lang="ts" setup>
import type { ComponentInstance } from '@core-types/utility'
import { CoreUiForm } from '#components'
import { z } from 'zod'
import type { BaseUiInputColors } from '../../base/BaseUiInput.vue'

const {
    onSubmit,
    color,
} = defineProps<{
    onSubmit: (data: z.infer<typeof schema>) => void | Promise<void>
    color?: BaseUiInputColors
}>()

const form = ref<ComponentInstance<typeof CoreUiForm> | null>(null)
const schema = z.object({
    email: zStringMailType,
})

export type schema = z.infer<typeof schema>

defineExpose({
    formComponent: form,
})

</script>

<style lang="scss" scoped>

</style>
